<template>
    <div style="background-color: #ffffff;">
        <div class="pageCenter text-left p-10">
            <div class="f24 bold text-center">回复评论</div>
            <lineTitle text="商品信息"></lineTitle>
            <div class="m-b-40">
                <div class="contentCenter">
                    <div class="table">
                        <div class="tableHeader m-t-25">
                            <div class="cell-2">商品信息</div>
                            <div class="cell-3">单价</div>
                            <div class="cell-4">数量</div>
                            <div class="cell-5">总价</div>
                        </div>
                        <div class="tableBody">
                            <div class="items m-t-10">
                                <div class="item p-t-10 p-b-10" v-for="(vv,ii) of detail.orderSchemeSpus" :key="ii">
                                    <div class="cell-2">
                                        <div class="merchandise">
                                            <div class="merchandiseImg"><img :src="$oucy.ossUrl+vv.productAvata"></div>
                                            <div class="merchandiseText p-l-10">
                                                <div class="name c-1 14 line-2">{{vv.spuName}}</div>
                                                <div class="specification c-2 m-t-15 f12 line-2">规格：{{vv.skuName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cell-3 unit"><span>￥{{vv.productPayfee}}</span></div>
                                    <div class="cell-4">
                                        <span class="c-1 f12">{{vv.pruductCount}}</span>
                                    </div>
                                    <div class="cell-5 price">￥{{(vv.productPayfee*vv.pruductCount).toFixed(2)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <lineTitle text="买家评论"></lineTitle>
            <div class="commentBody m-t-20">
                <div class="top df-jc-s-b df-ai-c">
                    <div class="user df-ai-c">
                        <img :src="$oucy.ossUrl + detail.orderUserauthAvata" class="avata"><span class="f14 c-6 m-l-10">{{detail.orderUserauthNickname}}</span>
                    </div>
                    <div class="df-ai-c">
                        <!-- <span class="c-6 bold">差评</span> -->
                        <el-rate class="m-l-10" v-model="detail.commentEvaluate" disabled>
                        </el-rate>
                        <span class="m-l-10 c-2">{{detail.commentCreatetime}}</span>
                    </div>
                </div>
                <div class="m-t-20">
                    <div class="text f14 c-6">{{detail.commentContent}}</div>
                    <div class="m-t-10" v-if="detail.commentAttachDtoList">
                        <MyMedia :srcList="$oucy.getSrcList(detail.commentAttachDtoList,'attachUrl')"/>
                        
                        <!-- /*<el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList">*/ -->
                        <!-- </el-image> -->
                    </div>
                </div>
                <div class="m-t-20 text-right">
                    <el-input type="textarea" placeholder="默认内容：感谢您的支持，我们会继续努力让你体验更好的服务！" rows="3" v-model="CommentReply.describer" maxlength="400" show-word-limit>
                    </el-input>
                    <el-button class="m-t-20" type="primary" @click="onSubmit">确认回复</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { spuComment } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            CommentReply: {
                commentId: null,
                describer: null,
            },

            commentId: null,
            enterpriseId: null,
            detail: {},

            value: null,
            url: null,
            items: [],
            srcList: [],
        }
    },
    computed: {

    },
    mounted() {
        this.commentId = this.$route.query.commentId
        this.enterpriseId = this.$route.query.enterpriseId
        this.getOrderSpuComment()
    },
    methods: {
        getOrderSpuComment() {
            spuComment.getOrderSpuComment({commentId:this.commentId}).then(res => {
                this.detail=res||{}
            })
        },

        onSubmit() {
            if (!this.CommentReply.describer) {
                this.CommentReply.describer = `感谢您的支持，我们会继续努力让你体验更好的服务！`
                // this.$alert('请输入回复内容','提示')
            }
            this.$oucy.keywordFiltr(this.CommentReply.describer).then(res=>{
                this.commentReply()
            },err=>{})
        },
        commentReply() {
            this.CommentReply.commentId = this.commentId
            spuComment.commentReply(this.CommentReply).then(res => {
                this.$message('回复成功')
                if (this.enterpriseId) {
                    this.$oucy.replace(`/enter/OMSCommentManagement?enterpriseId=${this.enterpriseId}`)
                } else {
                    this.$oucy.back()
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.commentBody {
    background: #F8F8F8;
    padding: 20px;
}

.tableHeader {
    width: 100%;
    display: flex;
    background: #FAFAFA;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
}

.cell-1 {
    flex: 1;
}

.cell-2 {
    flex: 6;
}

.cell-3,
.cell-4,
.cell-5,
.cell-6 {
    flex: 3;
    text-align: center;
}

.itemsHeader {
    text-align: left;
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        background: #E3EFFF;
        padding: 2px 8px;
    }
}

.item {
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-radius: 2px;
}

.item+.item {
    margin-top: 2px;
}

.merchandise {
    display: flex;
    align-items: center;
    text-align: left;

}

.merchandiseImg {
    width: 100px;
    height: 100px;
    background: #f1f1f1;

    img {
        width: 100px;
        height: 100px;
    }
}

.unit {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #555C68;

    span {
        padding: 5px 9px;
        background: #E7E7E7;
        display: inline-block;

    }
}

.price {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ED6732;
}

.lose {

    .price,
    .unit {
        color: #DDDDDD;

        span {
            background: transparent;
        }
    }
}

.checkboxLose {
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    background: #DDDDDD;
    border-radius: 2px;
}

.contentBottom {
    // position: fixed;
    // z-index: 99;
    // left: 0;
    // bottom: 0;
    // height: 64px;
    // width: 100%;
    padding-left: 20px;
    margin-top: 50px;
    background: #FFFFFF;
    // background: #F00;
    box-shadow: 0px 0px 10px 0px rgba(234, 233, 232, 0.75);
    border-radius: 2px;
}

.contentBottomBody {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #555C68;
}
.avata{
    width: 48px;
    height: 48px;
    border-radius:48px;
}
</style>